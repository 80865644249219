export const query = `
  _id
  _parent
  type
  ... on StbM2021Wk {
    name
    parent {
      _id
      ... on StbM2021League {
        name
        parent {
          _id type
          ... on StbM2021 { name startDate }
        }
      }
    }
    topathletes {
      team { _id name }
      person { _id givenName familyName }
      disciplines
      final
      score
    }
    status
    mode
    startDate
    venue { _id name address { streetAddress streetNumber postalCode addressLocality } }
    internalComment publicComment
    disciplines { _discipline order }
    judges { type person { _id givenName familyName } _discipline club { _id name } }
    teams {
      team { _id name club { _id } }
      home _startdiscipline order neutralwaiver
      trainer { person { _id givenName familyName } order }
      athletes {
        person { _id givenName familyName }
        order
      }
    }
    info {
      ersthelfer
      unfallhilfe
      rettungsdienst
      geraetenorm
      bemerkung
      wettkampfleiter
      hygienebeauftragter
      zuschauer
      ende
      dauer
      special
      attachments { _id filename mimetype }
    }
  }
`

export const resultquery = `
  _id
  type
  ... on StbM2021WkTeamAthleteResult {
    _team _person _discipline
    person { _id givenName familyName }
    team { _id name }
    order
    ne a b c d e f g h i parts
    eg
    e1 e2 e3 e4 deduction
    ok
    dscore escore penalty final score
    _sprung bonus
  }
`
