<template>
  <v-container fluid>
    <v-stepper
      v-model="step"
      @change="refetch"
    >
      <v-stepper-header>
        <v-stepper-step step="org" editable v-if="!$store.getters.isStbMWkDiscipline(id)">Organisation</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="vor" editable v-if="!$store.getters.isStbMWkDiscipline(id)">Vor Wettkampf</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step v-if="isHeim || isWkDiscipline(id)" step="bei" editable>Bei Wettkampf</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step v-if="isHeim" step="nach" editable>Nach Wettkampf</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step v-if="isHeim" step="finish" editable>Abschluss</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="org" v-if="!$store.getters.isStbMWkDiscipline(id)">
          <org :e="e" />
        </v-stepper-content>

        <v-stepper-content step="vor" style="padding:0px;" v-if="!$store.getters.isStbMWkDiscipline(id)">
          <vor :e="e" :df="df" :id="id" :r="ResultFind" />
        </v-stepper-content>

        <v-stepper-content v-if="isHeim || isWkDiscipline(id)" step="bei" style="padding:0px;">
          <bei :e="e" :df="df" :id="id" :r="ResultFind" :f="f" />
        </v-stepper-content>

        <v-stepper-content v-if="isHeim" step="nach" style="padding:0px;">
          <nach :e="e" :df="df" :id="id" :r="ResultFind" :f="f" />
        </v-stepper-content>

        <v-stepper-content v-if="isHeim" step="finish" style="padding:0px;">
          <finish :e="e" :df="df" :id="id" :r="ResultFind" :f="f" @refetch="refetch" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { useAuth } from '@/plugins/auth'
import { query, resultquery } from '@/views/components/stb_m_2021/content/input/query'
import { deleteQuery } from '@/plugins/graphql'
import Vue from 'vue'

export default {
  name: 'index',

  components: {
    Org: () => import('./org'),
    Vor: () => import('./vor'),
    Bei: () => import('./bei'),
    Nach: () => import('./nach'),
    Finish: () => import('./finish')
  },

  setup (props, context) {
    return {
      ...useAuth(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    df: {
      type: Array,
      required: true
    },
    f: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    Event: {},
    ResultFind: [],
    step: 'org',
    init: false
  }),

  computed: {
    e () {
      return {
        ...this.Event,
        teams: this.Event?.teams?.map(t => ({
          ...t,
          athletes: t.athletes?.map(a => ({
            ...a,
            results: this.ResultFind.filter(r => r._team === t.team._id && r._person === a.person._id)
          }))
        })) || []
      }
    },
    isHeim () {
      return this.isMaster.value || this.isAdmin(this.id) || this.e.teams?.filter(t => t.home).some(t => this.isTeam(t.team._id))
    }
  },

  methods: {
    async refetch () {
      console.log('refetch')
      await this.$apollo.queries.ResultFind.refetch()
    }
  },

  mounted () {
    if (this.$store.getters.isStbMWkDiscipline(this.id)) {
      this.step = 'bei'
      this.init = true
    }
  },

  apollo: {
    Event: {
      query: gql`query($id: UUID!) {
        Event(id: $id) { ${query} }
      }`,
      variables () {
        return {
          id: this.id
        }
      },
      result ({ data }) {
        this.Event = data?.Event || {}
        if (!this.init && this.Event.status) {
          this.init = true
          switch (this.Event.status) {
            case 'EventMovedOnline':
              this.step = 'vor'
              break
            case 'EventStarted':
              this.step = 'bei'
              break
            case 'EventFinished':
              this.step = 'finish'
              break
            default:
              this.step = 'org'
              break
          }
        }
      }
    },
    ResultFind: {
      query: gql`query($type: ResultType!, $event: UUID!) {
        ResultFind(type: $type, event: $event) { ${resultquery} }
      }`,
      variables () {
        return {
          type: 'stb_m_2021_wk_team_athlete',
          event: this.id
        }
      },
      fetchPolicy: 'no-cache',
      subscribeToMore: {
        document: gql`subscription($type: ResultType!, $event: UUID!) {
          ResultCreate(type: $type, event: $event) { ${resultquery} }
        }`,
        variables () {
          return {
            type: 'stb_m_2021_wk_team_athlete',
            event: this.id
          }
        },
        updateQuery (_, neu) {
          this.ResultFind.push(neu.subscriptionData.data.ResultCreate)
        }
      }
    },
    $subscribe: {
      EventUpdate: {
        query: gql`subscription($id: UUID!) {
          EventUpdate(id: $id) { ${query} }
        }`,
        variables () {
          return {
            id: this.id
          }
        }
      },
      ResultUpdate: {
        query: gql`subscription($type: ResultType!, $event: UUID!) {
          ResultUpdate(type: $type, event: $event) { ${resultquery} }
        }`,
        variables () {
          return {
            type: 'stb_m_2021_wk_team_athlete',
            event: this.id
          }
        },
        result ({ data }) {
          const i = this.ResultFind.findIndex(r => r._id === data.ResultUpdate._id)
          Vue.set(this.ResultFind, i, data.ResultUpdate)
        }
      },
      ResultDelete: {
        query: gql`subscription($type: ResultType!, $event: UUID!) {
          ResultDelete(type: $type, event: $event)
        }`,
        variables () {
          return {
            type: 'stb_m_2021_wk_team_athlete',
            event: this.id
          }
        },
        result (id) {
          deleteQuery('ResultFind', 'ResultDelete', this.ResultFind, id)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
